import React from "react";
import Navbar from "./components/Navbar";


import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import Home from "./pages/Acceuil";
import Activities from "./pages/events/activities";
import Statuts from "./pages/statuts";
import Contact from "./pages/contact";
import Creation from "./pages/events/creation";
import Evenement from "./pages/events/vivapEvent";
import BecomeAMember from "./pages/about/BecomeAMember";
import Footer from "./components/Footer";
import ComiteeMembers from './pages/about/ComiteeMembers';


function App() {
    return (
        <Router>
            <Navbar />
            <Routes>
                <Route path="*" element={<Home/>} /> {/* Fallback route */}
                <Route path="/become-a-member" element={<BecomeAMember/>} />
                <Route path="/contact" element={<Contact/>} />
                <Route path="/activities" element={<Activities/>} />
                <Route path="/statuts" element={<Statuts/>} />
                <Route path="/creation" element={<Creation/>} />
                <Route path="/vivapEvent" element={<Evenement/>} />
                <Route path="/comitee-members" element={<ComiteeMembers />} />
            </Routes>
            <Footer />
        </Router>
    );
}

export default App;
