import React from "react";
import { Grid, Card, CardContent, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    margin-top: 120px;
    @media (max-width: 600px) {
        margin-left: 15px;
        margin-right: 15px;
      }
`;

const PublishDate = styled(Typography)`
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-style: italic;
`;

const StyledCard = styled(Card)`
  position: relative; // Pour positionner absolument la date à l'intérieur de la carte
  margin-bottom: 20px; // Espacement entre les cartes si nécessaire
`;

const Title = styled.h1`
    color: #333;
    font-size: 2.5rem;
    margin-bottom: 20px;
    margin-top: 120px;
    text-align: left;
`;

const SubTitle = styled.h1`
    color: #333;
    font-size: 1.5rem;
    margin-bottom: 10px;
    text-align: left;
`;

const Activities = () => {
    // Dates de publication fixées
    const creationPublishDate = "08/04/2024";
    const vivapPublishDate = "20/05/2024";

    // Informations sur l'activité de création de l'association
    const creationActivity = {
        title: "Creation of the Association",
        description: "SHALOM - the Israeli  Association has been established! We are excited to develop it further and create enriching experiences and opportunities for Israeli students, as well as individuals interested in Israeli culture.",
        link: "/creation"
    };

    // Informations sur le premier événement majeur
    const vivapEvent = {
        title: "Vivapoly 2024",
        description: "We are happy to announce that we will take part in the 2024 Vivapoly edition! Come and see us at our stand!",
        link: "/events"
    };

    return (
        <Container maxWidth="lg">
            <Title>Associative Life</Title>
            <Grid container spacing={2}>

                {/* Carte de l'annonce de Vivapoly */}
                {<Grid item xs={12} md={6}>
                    <StyledCard>
                        <CardContent>
                            <SubTitle>{vivapEvent.title}</SubTitle>
                            <Typography variant="body1" color="textSecondary" paragraph>
                                {vivapEvent.description}
                            </Typography>
                            <Button variant="contained" color="primary" component={Link} to="/vivapEvent">
                                Learn More
                            </Button>
                            <PublishDate variant="caption" color="textSecondary">
                                {vivapPublishDate}
                            </PublishDate>
                        </CardContent>
                    </StyledCard>
                </Grid>}

                {/* Carte de l'activité de création de l'association */}
                <Grid item xs={12} md={6}>
                    <StyledCard>
                        <CardContent>
                            <SubTitle>{creationActivity.title}</SubTitle>
                            <Typography variant="body1" color="textSecondary" paragraph>
                                {creationActivity.description}
                            </Typography>
                            <Button variant="contained" color="primary" component={Link} to={creationActivity.link}>
                                Learn More
                            </Button>
                            <PublishDate variant="caption" color="textSecondary">
                                {creationPublishDate}
                            </PublishDate>
                        </CardContent>
                    </StyledCard>
                </Grid>

            </Grid>
        </Container>
    );
};

export default Activities;